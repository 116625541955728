import React from 'react';
import classNames from 'classnames';
import TreeMapHelp from '@/treemap/TreeMapHelp.atom';
import { TreemapChart, TreemapItem } from '@/treemap/TreemapChart.atom';
import { FakeLink } from '@/core/FakeLink';
import { TreemapBreadcrumbs, TreemapParent } from '@/treemap/treemap.store';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { provideVisualizationData } from '@/annotation/ckEditorPlugins/components/content.utilities';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { deserializeRange, SerializedRange } from '@/datetime/dateTime.utilities';
import { Timezone } from '@/datetime/timezone.service';
import { DisplayRangeSelector } from '@/trend/DisplayRangeSelector.molecule';

export interface TreemapPropsOnlyProps {
  helpKey?: string;
  breadcrumbs: TreemapBreadcrumbs;
  parent: TreemapParent;
  tree: TreemapItem[];
  isContent: boolean;
  /** Note: this needs to be serialized in order to render properly in Organizer as interactive content */
  displayRange: SerializedRange;
  timezone: Timezone;
  rangeEditingEnabled: boolean;
  onClickNode: (asset: { id: string } | undefined, isLeafNode: boolean) => void;
  afterChartUpdate?: () => void;
}

export const TreemapPropsOnly: React.FunctionComponent<TreemapPropsOnlyProps> = (props) => {
  const {
    helpKey,
    breadcrumbs,
    parent,
    tree,
    isContent,
    displayRange,
    timezone,
    rangeEditingEnabled,
    onClickNode,
    afterChartUpdate = () => {},
  } = props;
  if (headlessRenderMode()) {
    provideVisualizationData({
      ...props,
      visualization: Visualization.TREEMAP,
    });
  }

  return (
    <>
      {!helpKey && (
        <ul
          className={classNames(
            'unstyled-list searchBreadcrumbs flexColumnContainer flexWrap flexNoGrowNoShrink mb0 ml12',
            { mt5: isContent },
          )}
          data-testid="treemapBreadcrumbs">
          {breadcrumbs.map((breadcrumb) => (
            <li
              className={classNames({
                active: breadcrumb.id === parent.id || isContent,
              })}
              key={breadcrumb.id}>
              {breadcrumb.id !== parent.id && !isContent ? (
                <FakeLink onClick={() => onClickNode(breadcrumb, false)}>{breadcrumb.name}</FakeLink>
              ) : (
                breadcrumb.name
              )}
            </li>
          ))}
        </ul>
      )}
      {helpKey && (
        <div className="flexRowContainer flexCenter flexFill" data-testid="treemapHelp">
          <div className="alert alert-info fs16 p25 flexSelfCenter width-400">
            <TreeMapHelp type={helpKey} />
          </div>
        </div>
      )}
      {!helpKey && (
        <div className="flexFill flexColumnContainer mr0 mb0 ml0" data-testid="treemapChart">
          <TreemapChart tree={tree} afterChartUpdate={afterChartUpdate} onClickNode={onClickNode} />
        </div>
      )}
      <DisplayRangeSelector
        timezone={timezone}
        displayRange={deserializeRange(displayRange)}
        rangeEditingEnabled={rangeEditingEnabled}
        investigateRangeCopyDisabled={true}
        isInTopic={isContent}
        autoUpdateDisabled={isContent}
      />
    </>
  );
};
